/* Container for the whole page */
.manage-playlist-container {
  display: flex;
  padding: 2rem;
  background-color: #f1f1f1;
  /* min-height: 100vh; */
  width: 100%;
  margin: 0 auto;
  max-width: 74rem;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  }
  
  /* Information Banner */
  .info-banner {
    background-color: #e0f2ff;
    color: #0077cc;
    border: 1px solid #0077cc;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;
    /* max-width: 600px; */
    text-align: center;
    margin-bottom: 1.5rem;

  }
  
  .info-banner p {
    margin: 0;
    font-size: 1rem;
  }
  
  /* Form Container */
  .manage-playlist-form {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    /* max-width: 500px; */
    /* margin: auto; */
    /* text-align: center; */
  }
  
  .manage-playlist-form h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  /* Form Labels and Inputs */
  .manage-playlist-form label {
    display: block;
    font-weight: bold;
    /* margin: 1rem 0 0.5rem; */
    color: #333;
  }
  
  .manage-playlist-form input {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    border: 1px solid #f7c948;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
  }
  
  .manage-playlist-form input:focus {
    border-color: #f0b02f;
    box-shadow: 0 0 5px rgba(240, 176, 47, 0.3);
  }
  
  .manage-playlist-form small {
    display: block;
    margin-top: 0.3rem;
    color: #666;
  }
  
  /* Next Button */
  .next-button {
    margin-top: 1.5rem;
    background-color: #f7c948;
    border: none;
    padding: 0.7rem 2rem;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .next-button:hover {
    background-color: #f0b02f;
  }
  
  .next-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  .box-title{ 
    border-bottom: 1px solid #dedede;
    margin-bottom: 22px;
    padding-bottom: 10px;

}

/* Spinner styles */
.spinner {
  width: 13px;
  height: 13px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f7c948;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  


.next-button, .add-button {
  padding: 5px 10px;
  margin-top: 10px;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: left;
}


.manage-playlistCard-container{
  
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 0px;
  
}

.btn-ylw{
  background-color: #f7c948;
}

.btn-ylw:hover{
  background-color: #f0b02f;
}

.btn{
  padding: 0.7rem;
  font-size: 1rem;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f7c948;
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flx6h-r{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.btn-icn{
  height: 20px;
    width: 20px;
    margin: 0px 6px;
}

/* .popup-bg{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: gray;
  opacity: 0.4;
  z-index: 20;
} */


@media (max-width: 768px) {
  
}