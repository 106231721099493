/* Dashboard.css */
.dashboard {
    display: flex;
    height: 100vh;
  }
  
  .dashboard-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 216px;
  }
  

  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1001;
  }

  @media (min-width: 768px) and (max-width: 1000px) {
    .dashboard-main {
      margin-left: 70px;
    }
  }

  @media (max-width: 768px) {
    .dashboard-main {
      margin-left: 0px;
    }
  }
  