/* Header.css */

.header {
  background-color: #f7c948;
  color: #1a103d;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 900;
}

.header .header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header .icon-group {
  display: flex;
  gap: 15px;
}

.header .icon-container {
  position: relative;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
}

.header .notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #f7c948;
  color: #1a103d;
  border-radius: 50%;
  font-size: 0.75rem;
  padding: 2px 6px;
}


.header .credits {
  border: 1px solid #958321;
    border-radius: 14px;
    height: 34px;
    align-content: center;
    box-shadow: 0 0 5px #af981a;
    cursor: pointer;
}



.header .credits .credits-badge {
  background-color: #f7c948;
  color: #1a103d;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
}

.header .user-profile {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
}

.header .header.profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.header .arrow-down {
  font-size: 0.8rem;
  color: #666;
}

/* Dropdown Menu Styles */
.header .dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  z-index: 10;
  min-width: 150px;
}

.header .dropdown-item {
  padding: 10px 15px;
  background-color: #ffffff;
  color: #333;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.header .dropdown-item:hover {
  background-color: #f1f1f1;
}


@media (max-width: 768px) {

  .header{
    justify-content: flex-end;
    padding-right: 39px;
  }

  .header .user-profile span, h1{
  display: none;
 }
}

