* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html, #root, .App {
  height: 100%;
}

/* Ensure the main content area scales smoothly */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar, .footer {
  flex-shrink: 0;
}

.home {
  flex: 1;
}
