/* AddClientPopup.css */
.popup-background  {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  }
  
  .popup-content {
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 8px;
    max-width: 42rem ;

    width: 100%;
  }
  
  /* .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  } */
  
  .submit-button {
    background-color: #f7c948;
    border: none;
    color: black;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 4px;
    /* width: 100%; */
    margin-top: 10px;
  }
  
  .submit-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .popup-header{
    display: flex;
    justify-content: space-between;
  }

  .popup-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    width: 100%;
    border: 1px solid red;
    padding: 5px;
    border-radius: 5px;
    background: #ff000021;
  }


  .success-message {
    color: #4CAF50;
    margin-top: 10px;
    font-weight: bold;
}

.error-message {
    color: #f44336;
    margin-top: 10px;
    font-weight: bold;
}

.submit-button {
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

  