.support-page {
    padding: 20px;
  }
  
  .support-page .support-form {
    margin-bottom: 30px;
  }
  
  .support-form input,
  .support-form textarea,
  .support-form select {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .support-form button {
    background-color: #ffc107;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
  
  .ticket-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .ticket-list th,
  .ticket-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .ticket-list th {
    background-color: #ffc107;
    color: #fff;
  }

  .support-form textarea {
    width: 100%;
    min-height: 100px; /* Minimum height for empty textarea */
    max-height: 400px; /* Maximum height to avoid infinite growth */
    overflow-y: hidden; /* Hide vertical scrollbar */
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 4px;
    box-sizing: border-box;
  }


  /* Tabs */
.tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .tab.active {
    background-color: #ffc107;
    color: #fff;
  }
  
  .tab:not(.active):hover {
    background-color: #ffe58f;
  }
  
  /* Tab content */
  .new-ticket-tab,
  .my-tickets-tab {
    margin-top: 20px;
  }
  
  .status {
    text-transform: capitalize;
    font-weight: bold;
  }
  
  .status.open {
    color: orange;
  }
  
  .status.in-progress {
    color: blue;
  }
  
  .status.pending {
    color: darkorange;
  }
  
  .status.resolved {
    color: green;
  }
  
  .status.closed {
    color: gray;
  }
  
.ticket-details-popup-container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* padding: 10px; */
    flex-direction: row;
    overflow: auto;
    flex-wrap: wrap;
}
  .ticket-details-popup {
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 8px;
    max-width: 40rem;
    margin: 40px;
    overflow: hidden;
    width: 100%;
   
  }
  
  .ticket-details-popup .popup-content {
    max-width: 400px;
  }
  
  .ticket-details-popup h3 {
    margin-top: 0;
  }
  
  .ticket-details-popup p {
    margin: 10px 0;
  }
  
  .ticket-details-popup .close-button {
    display: inline-block;
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    width: fit-content;
    cursor: pointer;
  }
  
  .ticket-details-popup .close-button:hover {
    background-color: #ff3333;
  }
  
  
  