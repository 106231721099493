.note-cell-container .note-cell{
     display: flex;
    justify-content: space-between;
    color: #474545;
    font-weight: 500;
    height: 100%;
    
}


.note-cell-container input {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    border: 1px solid #f7c948;
    border-radius: 5px;
    outline: none;
    height: 37px;
    box-sizing: border-box;
    margin: 0;
  }
  
  .note-cell-container input:focus {
    border-color: #f0b02f;
    box-shadow: 0 0 5px rgba(240, 176, 47, 0.3);
  }

  .note-cell-container {
    /* position: relative;
    display: flex;
    align-items: center; */
    transition: background-color 0.3s ease, color 0.3s ease;
    height: 100%;
  }
  
  .note-cell-container.success {
    color: #0e8a2b; /* Success green color */
    animation: fadeOutSuccess 3s forwards;
  }
  
  .note-cell-container.error {
    color: #dc3545; /* Error red color */
    animation: fadeOutError 3s forwards;
  }
  
  .note-cell-container.loading .note-cell {
    color: transparent; /* Hide text when loading */
  }
  
  .note-cell-container  .spinner-icon {
    animation: spin 1s linear infinite;
    color: #007bff; /* Spinner color */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes fadeOutSuccess {
    0% { background-color: #e8f5e9; } /* Light green */
    100% { background-color: transparent; color: inherit; }
  }
  
  @keyframes fadeOutError {
    0% { background-color: #f8d7da; } /* Light red */
    100% { background-color: transparent; color: inherit; }
  }
  