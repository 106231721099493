/* Navbar Styles */
.navbar {
    background-color: #1a103d;
    color: white;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    position: relative;
    z-index: 10;
    justify-content: center;
  }
  .navbar .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 71rem;
  }
  
  .navbar .nav-logo, .navbar .nav-logolarge {
    display: flex;
    align-items: center;
  }
  
  .navbar .nav-logolarge {
    justify-content: center;
    /* height: 44px; */
  }
  
  .navbar .nav-logo img {
    width: 145px;
    /* height: 40px; */
    margin-right: 10px;
  }
  
  .navbar .nav-logolarge img {
    height: 70px;
    margin-right: 10px;
  }
  
  .navbar h1 {
    font-size: 1.2rem;
  }
  
  /* Regular nav links for larger screens */
  .navbar .nav-links {
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  .navbar .nav-links li a {
    color: white;
    text-decoration: none;
    padding: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .navbar .nav-links li a:hover {
    background-color: #f7c948;
    color: #1a103d;
    border-radius: 5px;
  }
  
  /* Menu button for mobile */
  .navbar .menu-button {
    display: none;
    font-size: 1.5rem;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  /* Drawer Styles for Mobile */
  .navbar .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #1a103d;
    color: white;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    padding-top: 2rem;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    z-index: 15;
  }
  
  .navbar .drawer.open {
    transform: translateX(0);
  }
  
  .navbar .drawer-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
  }
  
  .navbar .drawer-links li a {
    color: white;
    text-decoration: none;
    /* padding: 0.5rem; */
    display: block;
  }
  
  .navbar  .drawer-links li:hover{
    background-color: #f7c948;
    color: #1a103d;
    border-radius: 5px;
  }
  
  .navbar .drawer-links li.active {
    background-color: #767285;
    color: #f6f6f6;
    padding: 0.5rem;
    border-radius: 8px;
    border-left: 4px solid #f7c948;
    border-bottom: 1px solid #f7c948;
  }

  .navbar .nav-links li.active a {
    background-color: #f7c948;
    color: #1a103d;
    border-radius: 5px;
  }

  
  
  /* Overlay Styles */
  .navbar .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
  }

  
  .navbar .th{
    display: none;
   }
  
  /* Media Query for Mobile */
  @media (max-width: 768px) {
    .navbar .nav-links {
      display: none;
    }
  
    .navbar {
      justify-content: space-between;
    }
  
    .navbar .nav-logo {
      display: none;
    }

    .navbar .nav-logo.th {
      display: block;
    }

  
    .navbar .menu-button {
      display: block;
    }
  }
  