:root {
    --purple: #1a103d;
    --gold: #f7c948;
    --grey: #f1f1f1;
  }
   
  .landing-page {
    width: 100%;
    overflow-x: hidden;
  }
  
  .landing-page .landing-header {
    background: var(--purple);
    color: var(--gold) !important;
    text-align: center;
    /* padding: 50px 20px; */
  }
  
  .landing-page .header-content h1 {
    font-size: 3rem;
    margin: 0;
    color: var(--gold) !important;
  }
  
  .landing-page .header-content p {
    font-size: 1.2rem;
    margin-top: 10px;
    color: white !important;
  }
  
  .landing-page .features-section {
    padding: 40px 20px;
    background: white;
  }
  
  .landing-page .features-section h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: var(--purple);
  }
  
  .landing-page .features-grid {
    display: flex
;
    flex-wrap: wrap;
    max-width: 800px;
    gap: 20px;
    justify-content: center;
    flex-direction: row;
    margin: auto;
  }
  
  .landing-page .feature-card {
    background: var(--grey);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex: 1 1 calc(33.333% - 40px);
    max-width: 300px;
  }
  
  .landing-page .feature-card h3 {
    margin-bottom: 10px;
    color: var(--gold);
  }
  
  .landing-page .pricing-section {
    padding: 40px 20px;
    background: var(--grey);
  }
  
  .landing-page .pricing-section h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    color: var(--purple);
  }
  
  .landing-page .pricing-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .landing-page .pricing-card {
    display: flex;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.333% - 40px);
    max-width: 300px;
    cursor: pointer;
    background-color: #f5f5f5;
    text-align: center;
    border: 2px solid #e4e4e4;
    transition: 0.3s;
    flex-direction: column;
    align-items: center;
  }

  .landing-page .pricing-card:hover {
    border: 2px solid #f7c948;
    background-color: #fff9e6;
    transform: scale(1.1);
  }
  
  .landing-page .pricing-card h3 {
    margin-bottom: 10px;
    color: var(--purple);
  }
  
  .landing-page .pricing-card .price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 15px 0;
  }
  
  .landing-page .pricing-card .btn {
    background: var(--gold);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  
  .landing-page .pricing-card .btn:hover {
    background: #120a2a;
  }
  
  .landing-page .disclaimer-section {
    padding: 40px 20px;
    background: #ff600036;
    text-align: center;
  }



.disclaimer-section h2 {
    font-size: 2rem;
    color: var(--purple);
    margin-bottom: 20px;
  }
  
  .disclaimer-section p {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .disclaimer-list {
    list-style-type: none;
    padding: 0;
    font-size: 1.1rem;
  }
  
  .disclaimer-list li {
    /* font-size: 0.9rem; */
    color: #555;
    margin: 10px 0;
    text-align: left;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .disclaimer-list li svg {
    color: #af3939;
 

  }
  
  .landing-page .footer {
    background: var(--purple);
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .landing-page .footer a {
    color: var(--gold);
    text-decoration: none;
  }
  
  .landing-page .footer a:hover {
    text-decoration: underline;
  }

  .available-platforms {
    text-align: center;
    padding: 40px 20px;
    background-color: var(--grey);
  }
  
  .available-platforms h2 {
    font-size: 2rem;
    color: var(--purple);
    margin-bottom: 20px;
  }
  
  .platforms-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .platforms-grid img {
    width: 150px; /* Adjust as needed */
    height: auto;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .platforms-grid img:hover {
    transform: scale(1.05);
  }
  

  .reseller-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--gold);
    padding: 20px 40px;
    border-radius: 8px;
    margin: 40px auto;
    max-width: 71rem;
    gap:  17px;
  }
  
  .reseller-content h2 {
    font-size: 1.5rem;
    color: var(--purple);
    margin: 0;
  }
  
  .reseller-content .highlight {
    font-size: 1.1rem;
    color: var(--purple);
    margin-top: 5px;
  }
  
  .reseller-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .reseller-button a {
    text-decoration: none;
    color: #e4e4e4;
    display: flex;
    align-items: center;
    gap: 10px;
    
  }

  .reseller-button a svg{
   font-size: 2rem;
    
  }


  
  .reseller-button i {
    font-size: 1.2rem;
  }
  
  .reseller-button:hover {
    background-color: #333;
  }

  .header-container {
    background: linear-gradient(135deg, #1a103d, #1e1445);
    padding: 60px 20px;
    text-align: center;
    color: #f7c948;
    position: relative;
    overflow: hidden;
  }
  
  .header-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url('/assets/background-header.jpg') no-repeat center center/cover; */
    opacity: 0.1; /* Adjust opacity to blend with gradient */
    z-index: 1;
  }
  
  .header-content {
    position: relative;
    z-index: 2;
  }
  
  .header-content h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .header-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    margin-top: 20px;
  }
  
  .cta-button .btn-get-started {
    background-color: #f7c948;
    color: #1a103d;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .cta-button .btn-get-started:hover {
    background-color: #e6b93e;
    color: white;
  }

  .landing-page .landing-header .nav-logo img {
    width: 145px;
    /* height: 40px; */
    margin-right: 10px;
    display: none;
  }

  @media(max-width : 768px){
    .landing-page .landing-header .nav-logo img {
      display: inline;
    }

    .landing-page .reseller-section{
      flex-direction: column;
    }
  }
  
  
  