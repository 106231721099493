
/* Container for the playlist card */
.playlist-card {
    width: 195px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #e4e4e4;
    transition: transform 0.2s;
    cursor: pointer;
  }
  
  .playlist-card:hover {
    transform: translateY(-5px);
    background-color: #fff9e6;
    border: 1px solid #f7c948;
  }
  
  /* Playlist Name Styling */
  .playlist-card .playlist-name {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
    OVERFLOW: hidden;
    WIDTH: 100%;
  }
  
  /* Icon for the type (Xtream or Link) */
  .playlist-card .playlist-logo {
    font-size: 2em;
    /* margin: 15px 0; */
  }
  
  .playlist-card .type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .playlist-card  .code-icon {
    color: #5f5f5f; /* Green color for Xtream */
  }
  
  .playlist-card  .link-icon {
    color: #2196f3; /* Blue color for Link */
  }
  
  /* Actions section with buttons for edit and delete */
  .playlist-card .playlist-actions {
    display: flex;
    gap: 15px;
  }
  
  /* Style for edit and delete buttons */
  .playlist-card .playlist-actions button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .playlist-card .playlist-actions button:hover {
    background-color: #f7c948;
  }
  
  .playlist-card .playlist-actions img {
    width: 20px;
    height: 20px;
  }
  
  
  
  
  
  @media (max-width: 768px) {
    .playlist-card {
      width: 100%;
    }
  }
  
  
  
  
