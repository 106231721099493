.purchase-credits-page {
    max-width: 9000px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .step-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .step {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f9f9f9;
    color: #555;
    flex: 1;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .step.active {
    background: #ffd700;
    color: #fff;
    font-weight: bold;
  }
  
  .step:last-child {
    margin-right: 0;
  }

  .credits-content{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    align-items: baseline;
    justify-content: center;
  }
  
  .step-content {
    padding: 20px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .credits-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .payment-details input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .step-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .back-button,
  .next-button,
  .confirm-button {
    padding: 10px 20px;
    border: none;
    background: #ffd700;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .back-button:hover,
  .next-button:hover,
  .confirm-button:hover {
    background: #e6b800;
  }
  
  .credit-equivalents {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .credit-equivalents div {
    text-align: center;
  }
  
  .credits-table {
    margin-top: 20px;
  }
  
  .credits-table table {
    box-shadow: 0 0 6px #80808085;
    border-radius: 5px;
    overflow: hidden;
    width: 300px;
  }
  
  .credits-table th,
  .credits-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .credits-table th {
    background: #f7c948;
    color: #1a103d;
  }
  
  .confirmation-step {
    text-align: center;
  }
  
  
  .stepper-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }

  .credit-system-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .desc-bill{
    display: flex
;
    flex-direction: column;
    align-items: center;
  }
  
  .bill{
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    border: 2px solid #e4e4e4;
    transition: 0.3s;
    /* width: 45%; */
    /* border: 2px solid #f7c948;
    background-color: #fff9e6; */
  }

  .bill .crd{
    border-bottom: 1px solid #80808038;
  }

  .bill .crdt{
    font-weight: bold;
    font-size: 30px;
    padding: 5px;
    background: #f7c948;
    border-radius: 5px;
  }

  .bill .units{
    font-weight: bold;
  }

  .bill .total{
    font-size: 25px;
    color: black;
  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    text-align: center;
  }
  
  .step-circle {
    background-color: #dcdcdc;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    z-index: 2;
  }
  
  .step-label {
    font-size: 14px;
    color: #666;
  }
  
  .step-line {
    height: 2px;
    background-color: #dcdcdc;
    position: absolute;
    top: 20px;
    left: 50%;
    right: -50%;
    z-index: 1;
  }
  
  .step-item.active .step-circle {
    background-color: #ffcc00;
    color: #fff;
  }
  
  .step-item.completed .step-circle {
    background-color: #4caf50;
    color: #fff;
  }
  
  .step-item.completed .step-line {
    background-color: #4caf50;
  }
  
  .step-content {
    margin-top: 20px;
  }
  
  .step-navigation {
    margin-top: 20px;
    text-align: right;
  }
  
  .step-navigation button {
    margin-left: 10px;
    padding: 10px 15px;

    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .step-navigation button.next {
    background-color: #f7c948;
  }

  .step-navigation .back-button:hover{
    background-color: #3d3d3d;
    box-shadow: 1px 1px 6px grey
  }

  /* .step-navigation.back button {
    background-color: #f7c948;
  }
   */
  /* .step-navigation button:hover {
    background-color: #e6b800;
  } */

  
  .stepper-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    text-align: center;
  }
  
  .step-circle {
    background-color: #dcdcdc;
    color: #000;
    font-size: 16px;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    z-index: 2;
  }
  
  .step-label {
    font-size: 14px;
    color: #666;
  }
  
  .step-line {
    height: 2px;
    background-color: #dcdcdc;
    position: absolute;
    top: 20px;
    left: 50%;
    right: -50%;
    z-index: 1;
  }
  
  .step-item.active .step-circle {
    background-color: #ffcc00;
    color: #fff;
  }
  
  .step-item.completed .step-circle {
    background-color: #4caf50;
    color: #fff;
  }
  
  .step-item.completed .step-line {
    background-color: #4caf50;
  }
  
  .cost-summary {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  

  @media(max-width : 768px){
    .credits-content{
      flex-direction: column;
    }
  }