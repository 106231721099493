/* ResellerLanding.css */

.reseller-landing-container{
  display: flex;
  padding: 2rem;
  background-color: #f1f1f1;
  /* min-height: 100vh; */
  width: 100%;
  margin: 0 auto;
  max-width: 74rem;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.reseller-landing {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  }
  
  hr.brl{
    border: 1px solid #cdc2c2;
    margin: 0 60px 60PX;
  }


  .reseller-landing-container h1 {
    color: #1a103d;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .reseller-landing-container p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .btn-register, .btn-login {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    color: #fff;
  }
  
  .btn-register {
    background-color: #f7c948;
  }
  
  .btn-login {
    background-color: #333;
  }
  
  .features {
    margin-bottom: 2rem;
  }
  
  .features h2 {
    color: #1a103d;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .feature-cards {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .feature-card {
    background: #f6f6f6;
    padding: 1rem;
    border-radius: 8px;
    /* width: 200px; */
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature-card h3 {
    font-size: 1.1rem;
    color: #f7c948;
    margin-bottom: 0.5rem;
  }
  
  .feature-card p {
    font-size: 0.9rem;
  }
  
  .credits-system {
    margin-top: 2rem;
  }
  
  .credits-system h2 {
    color: #f7c948;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .credits-system p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .credit-conversion {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
  
  .credit-box {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .credit-period {
    background-color: #d3d3d3;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: 400
  }
  
  .credit-equals {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .credit-value {
    background-color: #f7c948;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: 600;
  }
  
  .credit-table {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #d4d4d4;
    /* box-shadow: 0px 0px; */
    box-shadow: 0px -1px 12px rgb(0 0 0 / 24%) 
  }
  
  .credit-table table {
    width: 100%;
    max-width: 400px;
    border-collapse: collapse;
    margin: auto;
  }
  
  .credit-table th, .credit-table td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .credit-table th {
    background-color: #f7c948;
    color: #fff;
  }
  
  .credit-table td {
    font-size: 0.9rem;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .feature-cards, .credit-conversion {
      flex-direction: column;
    }
  
    .credit-table table {
      font-size: 0.85rem;
    }
  }
  