.edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 11;
}

.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;

}

.edit-popup h3 {
  margin-top: 0;
}

.edit-popup input,
.edit-popup select {
  width: 100%;
  /* margin-bottom: 10px; */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.mrg-tp-50 {
  margin-top: 50px;
}

.edit-popup .info-banner {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #d0bc81;
  justify-content: space-between;
  display: flex;
}

.edit-popup .info-banner button {

  border: 0px;
  height: 30px;
  width: 30px;

  padding: 0px;

}

/* .edit-popup .info-banner button img{
    height: 20px;
    width: 20px;

  } */