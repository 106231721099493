.settings-popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .settings-popup-content {
    background-color: #ffffff;
    padding: 20px;
    max-width: 460px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
  }
  
  .settings-popup-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0.5rem 0 2.5rem;
  }
  
  .close-button {
    background: #ececec;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #333;
    border-radius: 50%;
    /* padding: 6px; */
    height: 33PX;
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .close-button:hover {
    color: #ffffff;
    background: #f0b02f;
  }
  
  .mac-address-display1 {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    font-size: 1em;
    color: #333;
    text-align: center;
  }
  
  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  .action-buttons  button{
    width: 100%;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }

  .activate{
    background-color: #ffcc00;
    color: #ffffff;
  }
  
  .lock {
    background-color: #ff9900;
    color: #333;
  }
  
  .retract {
    background-color: #e74c3c;
    color: #ffffff;
  }
  
  .transfer {
    background-color: #3498db;
    color: #ffffff;
  }
  
  .settings-popup-content .action-button svg {
    margin-right: 5px;
  }


  /* Disabled Retract Button */
.action-button.retract.disabled {
  cursor: not-allowed;
  background-color: #d3d3d3;
  color: #aaa;
}

/* Confirmation Popup */
.confirmation-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  border: 1px solid #8080804f;
}

.confirmation-popup p {
  margin-bottom: 20px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.confirm-button {
  padding: 8px 16px;
  background-color: #f0c818;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.cancel-button {
  padding: 8px 16px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.confirm-button:hover {
  background-color: #e0b516;
}

.cancel-button:hover {
  background-color: #bbb;
}


.action-button {
  position: relative;
}

.action-button[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 50%;
  bottom: calc(100% + 5px);
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 1;
  pointer-events: none;
}

.action-button[data-tooltip]::after {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.action-button[data-tooltip]:hover::after {
  opacity: 1;
}


  