.footer {
  background-color: #f1f1f1;
  color: #666;
  padding: 1rem;
  text-align: center;
  font-size: 0.9rem;
  display: flex
;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid #d8d8d8;
  align-items: center;
  flex-wrap: wrap;
  }
  
  .footer .footer-links {
    /* margin-top: 0.5rem; */
  }
  
  .footer .footer-links a {
    color: #666;
    text-decoration: none;
    margin: 0 0.5rem;
  }
  
  .footer .footer-links a:hover {
    color: #1a103d;
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {

    .footer {
        background-color: #f1f1f1;
        color: #666;
        padding: 1rem;
        text-align: center;
        font-size: 0.9rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: stretch;
    }

    .footer .footer-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: space-between;
        justify-content: space-around;
        flex-wrap: nowrap;
    }
  
    .footer .footer-links a {
      margin: 0.25rem 0; /* Adjust spacing for vertical layout */
    }
  }
  