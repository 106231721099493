/* ResellerLogin.css */

.reseller-login-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 1000; */
  /* padding: 10px; */
  flex-direction: row;
  overflow: auto;
  flex-wrap: wrap;
  }
  
  .login-box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 320px;
    text-align: center;
    position: relative;
  }
  
  .login-box h2 {
    background-color: #f7c948;
    color: #333;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 10px 0 33px 0;
    margin: -2rem -2rem 1.5rem;
    width: 238px;
    height: 50px;
    align-content: center;
    box-shadow: 3px 2px 9px #80808078;
}


.error-message {
  color: #d9534f;
  font-size: 0.85rem;
  margin-bottom: 1rem;
  background: #dc0d0d30;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid;
}
  
  
  .login-box label {
    display: block;
    color: #666;
    font-size: 0.9rem;
    margin: 1rem 0 0.5rem;
    text-align: left;
  }
  
  .login-box input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #f7c948;
    border-radius: 5px;
    outline: none;
  }
  
  .login-links {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  
  .forgot-password {
    color: #d32f2f;
    text-decoration: none;
  }
  
  .register-link {
    color: #333;
    text-decoration: none;
  }
  
  .signin-button {
    background-color: #f7c948;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;
    width: 100%;
  }
  
  .signin-button:hover {
    background-color: #e6b238;
  }
  
  .login-box a:hover {
    text-decoration: underline;
  }

  
  .nav-logo-lgn{
    margin: auto;
  }

  .nav-logo-lgn img{
  width: 145px;
  }
