.activate-popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .activate-popup-background .activate-popup-content {
    background-color: #ffffff;
    padding: 20px;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .activate-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .popup-body{
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 6px;
    
  }
  
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
  }
  
.popup-content  .plan-options {

  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  max-width: 336px;
  flex-direction: row;
  flex-wrap: nowrap;
  --tw-space-y-reverse: 0;
  margin: 0;
}

 

  .plan-options label {
    display: block;
    margin: 10px 0;
  }
  
  .activate-button {
    background-color: #ffcc00;
    border: none;
    padding: 10px;
    color: #333;
    border-radius: 4px;
    cursor: pointer;
    /* width: 100%; */
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  

  .spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .activation-message {
    margin-top: 10px;
    font-weight: bold;
    color: #4CAF50;
  }
  
  .confirm-extend-popup {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .confirm-button, .cancel-button {
    padding: 8px 16px;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  .confirm-button {
    background-color: #4CAF50;
    color: white;
  }
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  