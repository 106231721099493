/* CustomerTable.css */
.ag-theme-alpine .ag-header-cell-label {
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
  }
  
  /* Styles for custom columns */
  .system-tizen {
    background-color: #007bff;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .system-android {
    background-color: #28a745;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
  }
  
  .plan-tag {
    background-color: #f7c948;
    color: #1a103d;
    padding: 4px 8px;
    border-radius: 5px;
  }


.plan-tag.expired {
  background-color: red;
  color: white;
  font-weight: bold;
}

.plan-tag.trial {
  background-color: grey;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}

  
 

  /* Style for AG Grid actions column */
.ag-theme-alpine .ag-cell[col-id="actions"] {
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .ag-theme-alpine .ag-cell[col-id="resellerNote"] {
    padding: 0;
  }

  .ag-theme-alpine .ag-cell[col-id="mac"] {
    font-weight: 700;

  }

  .ag-theme-alpine .ag-cell {
    user-select: text;
    cursor: default;
  }

  .ag-theme-alpine {
    overflow: visible;
  }

  /* CustomerTable.css */
.customer-table {
    padding: 20px;
  }
  
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .search-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    /* width: 200px; */
  }
  
  .table-actions {
    display: flex;
    gap: 10px;
  }
  .search-zone{
    width: 100%;
    /* padding: 0.7rem; */
    margin: 0.5rem 0;
    max-width: 457px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
  }

  .btn-new-client, .btn-activate, .btn-mass-host {
    padding: 10px 10px;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 8px;
    align-items: center;
  }

  .btn-new-client svg, .btn-activate svg , .btn-mass-host svg {
    width: 19px;
    height: 19px;

  }
  
  .btn-new-client,.btn-activate {
    background-color: #f7c948;
  }
  
  .btn-activate:hover, .btn-new-client:hover {
    background-color:#d8ae39  ;
  }
  
  .btn-mass-host {
    background-color: #333;
  }
  
  /* Modal Styling */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    z-index: 1000;
  }
  

  .page-title{
    display: none;
  }
  .radio-filter-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
    padding: 14px;
    background: #f7f7f7;
    border-radius: 5px;
    border: 1px solid #c9c9c9;
  }
  
  .radio-button {
    display: flex;
    align-items: center;
    gap: 8px;
    /* cursor: pointer; */
  }
  
  .radio-button input[type="radio"] {
    display: none; /* Hide default radio button */
  }
  
  .radio-button span {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #F0C818; /* Yellow border */
    border-radius: 50%;
    background-color: white;
    position: relative;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .radio-button input[type="radio"]:checked + span {
    background-color: #F0C818; /* Yellow background when checked */
    border-color: #C17E04; /* Darker border when checked */
  }
  
  .radio-button input[type="radio"]:checked + span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: white; /* Inner white dot */
    border-radius: 50%;
  }
  
  .radio-button label {
    font-size: 14px;
    color: #333333;
    cursor: pointer;
  }
  


  @media(max-width : 768px){
    .page-title{
      display: block;
    }
  }
 
  