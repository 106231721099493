
  .privacy-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .privacy-content {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-container  h1 {
    color: #1a103d;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .privacy-container  h2 {
    color: #1a103d;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .privacy-container  p {
    margin: 10px 0;
  }
  
  .privacy-container  ul {
    margin: 10px 0 10px 20px;
  }
  
  .privacy-container  ul li {
    margin: 5px 0;
  }
  
  .privacy-container  .link {
    color: #007BFF;
    text-decoration: none;
  }
  
  .privacy-container  .link:hover {
    text-decoration: underline;
  }
  