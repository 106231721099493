.check-mac-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .check-mack-box{
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
    margin: 10px;
  }
  
  .check-mac-container h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .mac-search {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;
  }
  
  .mac-search input {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .mac-search button {
    background-color: #f1c40f;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .mac-search button:hover {
    background-color: #d4ac0d;
  }
  
  .error-message {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  .client-details {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    gap: 26px;
    flex-direction: column;
  }
  
  .client-details h3 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .client-details p {
    margin: 5px 0;
    font-size: 0.95rem;
  }

  .plan-tag {
    background-color: #f7c948;
    color: #1a103d;
    padding: 4px 8px;
    border-radius: 5px;
  }


.plan-tag.expired {
  background-color: red;
  color: white;
  font-weight: bold;
}

.plan-tag.trial {
  background-color: grey;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}
  