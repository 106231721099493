/* Container for the activation page */
.activation-container {
  display: flex;
  padding: 2rem;
  background-color: #f1f1f1;
  /* min-height: 100vh; */
  margin: 0 auto;
  /* max-width: 74rem; */
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  }
  
  /* Alert Banner Styles */
  .alert-banner {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #d0bc81;
    border-radius: 5px;
    padding: 1rem;
    width: 100%;
    /* max-width: 600px; */
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  /* Activation Form Container */
  .activation-form {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
    /* max-width: 500px; */
  }
  
  .box-title{ 
      border-bottom: 1px solid #dedede;
      margin-bottom: 22px;
      padding-bottom: 10px;
 
  }


  .activation-form h2 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  /* Form Label and Input */
  .activation-form label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .activation-form input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    border: 2px solid #f7c948;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .activation-form small {
    display: block;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
  }
  
  /* Checkbox Container */
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 0.5rem;
  }
  
  .checkbox-container label {
    font-size: 0.9rem;
    color: #333;
  }
  
  /* Submit Button */
  .activation-form button {
    /* width: 100%; */
    padding: 0.7rem;
    font-size: 1rem;
    color: white;
   
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .activation-form button.next {
    background-color: #f7c948;
  }
  
  .activation-form button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  

  

  
  .activation-form button:hover:not(:disabled) {
    background-color: #f0b02f;
  }
  


  .plan-selection-container {
    padding: 2rem;
    /* max-width: 600px; */
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .payment-form{
    max-width: 400px;
    margin: auto;
  }
  
  h2 {
    /* text-align: center; */
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .mac-display {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    width: fit-content;
    margin: 10px auto 35px;
  }
  
  .mac-display strong {
    display: block;
    background-color: #f7c948;
    color: #333;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 0.5rem;
  }
  
  h3 {
    font-size: 1.2rem;
    margin-top: 1.5rem;
  }
  
  .plan-options {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    max-width: 336px;
    flex-direction: row;
    flex-wrap: nowrap;
    /* --tw-space-y-reverse: 0; */
    margin: 13px auto;
  }
  
  .plan-option {
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    border: 2px solid #e4e4e4;
    transition: 0.3s;
    width: 100%;
  }
  
  .plan-option.selected {
    border: 2px solid #f7c948;
    background-color: #fff9e6;
    transform: scale(1.1);
  }
  

/* Payment Methods */
.payment-methods {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  margin: 32px auto;
  margin-bottom: 1.5rem;
  max-width: 400px;
}

.payment-option {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: 0.3s;
}

.plan-options p.tt {
font-weight: bold;
/* font-size: large; */
}

.payment-option.selected {
  border: 2px solid #f7c948;
  background-color: #fff9e6;
}

.payment-option input[type="radio"] {
  display: none;
}

.payment-option span {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
}

/* Payment Icons */
.payment-icons {
  display: flex;
  gap: 0.5rem;
}

.payment-icons img {
  width: 30px;
  height: auto;
}

/* Input Fields and Form Buttons */
input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 0.7rem;
  margin: 0.5rem 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  /* max-width: 400px; */
}
  
  .terms {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .terms label {
    margin-left: 0.5rem;
  }
  
  .terms a {
    color: #0077cc;
    text-decoration: underline;
  }
  
  .pay-button {
    width: 100%;
    padding: 0.7rem;
    margin-top: 1rem;
    background-color: #f7c948;
    border: none;
    color: #fff;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f7c948;
  }
  
  .pay-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .back-button {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    background-color: #444;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .activation-form .back-button:hover:not(:disabled) {
    background-color: #3d3d3d;
    box-shadow: 1px 1px 6px grey
  }
  

  /* Custom Checkbox Styling */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Style the label as a custom checkbox */
.checkbox-container label {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

/* Add a box to represent the custom checkbox */
.checkbox-container label::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  transition: all 0.3s;
  background-color: white;
}

/* Checked state */
.checkbox-container input[type="checkbox"]:checked + label::before {
  background-color: #f7c948;
  border-color: #f7c948;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Checkmark for the checked state */
.checkbox-container input[type="checkbox"]:checked + label::before {
  content: "✓";
  font-size: 16px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Focused state */
.checkbox-container input[type="checkbox"]:focus + label::before {
  border-color: #f0b02f;
  box-shadow: 0 0 3px 2px rgba(240, 176, 47, 0.3);
}


/* Disable form style */
.activation-form.disabled {
  opacity: 0.6;
  pointer-events: none;
}

/* Spinner styles */
.spinner {
  width: 13px;
  height: 13px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #f7c948;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media(max-width:768px){
  .activation-form{
    padding: 1rem;
  }
  .plan-options {
    flex-direction: column;
  }
}
