.home {
    text-align: center;
    padding: 2rem;
    background-color: #eaeaea;
    min-height: calc(100vh - 160px); /* Adjust based on navbar/footer height */
  }
  
  .home h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .pay-button {
    background-color: #f7c948;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    color: #333;
    margin-top: 1rem;
  }
  
  /* Hover effect */
  .pay-button:hover {
    background-color: #f0b02f;
  }
  
  /* Media Query for Smaller Screens */
  @media (max-width: 768px) {
    .home {
      padding: 1rem;
    }
  
    .home h2 {
      font-size: 1.5rem;
    }
  
    .pay-button {
      width: 100%;
      font-size: 1rem;
    }
  }
  