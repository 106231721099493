.popup-background {
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* padding: 10px; */
    flex-direction: row;
    overflow: auto;
    flex-wrap: wrap;
  }
  
  .popup-content {
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 8px;
    max-width: 64rem;
    margin: 40px;
    overflow: hidden;
    width: 100%;
    /* position: relative; */
  }
  
  .popup-header {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #d0bc81;
    justify-content: space-between;
    display: flex;
    padding: 17px;
    align-items: center;
    flex-direction: row;
  }

  .popup-header h2 {
    margin-bottom: 0;
  }
  
  .mac-address-display {
    display: flex;
    align-items: center;
    /* background-color: #f7c948; */
    padding: 10px;
    border-radius: 4px;
    color: #333;
    font-weight: bold;
    flex-direction: column;
    width: 100%;
  }

  .mac-address-display span {
    background-color: #f7c948;
    padding: 7px;
    font-size: larger;
    border-radius: 5px;
  }
  .playlist-rows {
    width: 100%;
  }
  
  .playlist-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    padding: 2px 20px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    
  }

  .delete-row-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1em;
  }

  .playlist-row  .rows-d{
    width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  align-items: baseline;
  gap: 7px;
  }   


  .playlist-row select {
    height: 39px;
    border-color: #e4e4e4;
    border-radius: 4px;
    width: 105px;
  }

  .playlist-row select option[selected] {
    background-color: #f7c948;
  }

  .t-c{
    
  }

  .d-c{
    width: 100%;
  }

  .n-c{
    width: 170px;
  }
  
  
  .delete-button {
    background: transparent;
    border: none;
    color: #ff5c5c;
    cursor: pointer;
  }
  
  .save-button, .add-row-button, .delete-all-button {
    background-color: #f7c948;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    gap: 5px;
  }

  .row-divider {
    border: none;
    border-top: 1px solid #ddd;
    margin: 17px 0;
  }
  
  

  @media (max-width: 768px) {

    .playlist-row  .rows-d{
    align-items: baseline;
    gap: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;

    }  

    .t-c select{
    width: 100%;
    }

    .t-c, .n-c ,  .d-c{
      width: 100%;
    }
  }