.new-ticket-tab{
    align-items: center;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 auto;
    max-width: 74rem;
    padding: 2rem;
    width: 100%;
}

.support-form {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px #0000001a;
    padding: 2rem;
    width: 100%;
  }
  
  .support-form input,
  .support-form textarea,
  .support-form select {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .support-form button {
    background-color: #ffc107;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }