.activation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  .activation-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    max-width: 500px;
    text-align: center;
  }
  
  .activation-header h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .activation-content {
    margin-bottom: 20px;
  }
  
  .activation-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .activation-button {
    background-color: #222628;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin: 5px;
  }
  
  .activation-button:hover {
    background-color: #45a049;
  }
  
  .activation-footer {
    margin-top: 20px;
  }
  
  .resend-message {
    color: #4caf50;
    margin-top: 10px;
  }
  