.action-buttons-container{
    display: flex;
    gap: 10px;
    justify-content: space-around;
}


.action-buttons-container .action-button {
    padding: 5px;
    font-size: 19px;
    cursor: pointer;
    border: none;
    color: white;
    border-radius: 4px;
    width: 40px;;
    display: flex;
    justify-content: center;
    /* align-content: center; */
    flex-direction: row;

  }
  
  .action-buttons-container .action-button.ylw:hover {
    background-color: #e0a800;
  }

   .ylw{
    background-color: #ffc107;
  }

  .mgn{
    background-color: #1a103d;
  }
