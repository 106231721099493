

/* Spinner styles */
.spinner-b {
    width: 34px;
    height: 34px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #f7c948;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    display: inline-block;
    z-index: 11;
    position: absolute;
    top: 50%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .sp-bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }