.profile-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  margin: 10px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile-field label {
  font-size: 16px;
  font-weight: bold;
  color: #444;
}

.profile-field span {
  font-size: 14px;
  color: #666;
}

.name-edit-container,
.name-display {
  display: flex;
  align-items: baseline;
  gap: 10px;
  flex-direction: row;
}

.name-edit-container div{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  width: 100%;
}


.password-section{
  border-top: 1px solid rgba(128, 128, 128, 0.397);
  border-top: 1px solid rgba(128, 128, 128, 0.397);
    padding-top: 40px;
    margin-top: 25px;
}

input[type='text'],
input[type='password'] {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-button,
.save-button,
.update-password-button {
  background-color: #f7c948;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-button {
  padding: 5px;
}


.cancel-button svg {
  height: 17px;
  width: 17px;
}

.edit-button:hover,
.save-button:hover,
.update-password-button:hover {
  background-color: #ffa502;
}

.password-section input {
  margin-bottom: 8px;
}

.password-section button {
  width: fit-content;
  margin-top: 10px;
}

@media(max-width : 768px){
  .name-edit-container,
.name-display {
  flex-direction: column;
}
}