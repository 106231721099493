.reseller-register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: #f6f6f6;
  font-family: Arial, sans-serif;
  margin: 70px auto;
  width: fit-content;
  }
  
  .register-box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: 320px;
    text-align: center;
    position: relative;
  }
  
  .register-box h2 {
    background-color: #f7c948;
    color: #333;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 10px 0 33px 0;
    margin: -2rem -2rem 1.5rem;
    width: 238px;
    height: 50px;
    align-content: center;
    box-shadow: 3px 2px 9px #80808078;
  }
  
  .register-box label {
    display: block;
    color: #666;
    font-size: 0.9rem;
    margin: 1rem 0 0.5rem;
    text-align: left;
  }
  
  .register-box input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #f7c948;
    border-radius: 5px;
    outline: none;
  }

  .login-links {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
  
  .signup-button {
    background-color: #f7c948;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-top: 1.5rem;
    cursor: pointer;
    width: 100%;
  }
  
  .signup-button:hover {
    background-color: #f4b328;
  }
  
  /* .error-message{
    color: #d9534f;
    font-size: 0.85rem;
    margin-bottom: 1rem;
    background: #dc0d0d30;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid;
  } */
  .success-message {
    color: #5cb85c;
    font-size: 0.85rem;
    margin-bottom: 1rem;
    background: #33dc0d30;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid;
  }
  

  .confirmation-box {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .confirmation-icon {
    font-size: 2em;
    color: #28a745;
    margin-bottom: 15px;
  }
  
  .confirmation-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .button {
    background-color: #ffc107;
    border: none;
    padding: 10px 20px;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .button:hover {
    background-color: #e0a800;
  }
  