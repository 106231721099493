/* Sidebar.css */

/* Sidebar Base Styles */
.sidebar {
  width: 216px;
  background-color: #1a103d;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 0.3s ease;
  z-index: 905;
}

.sidebar.open {
  transform: translateX(0); /* Slide in */
}

.sidebar .sidebar-header {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 112px;
}

.sidebar .sidebar-logo {
  width: 150px;
  height: 64px;
}

/* Sidebar Navigation */
.sidebar .sidebar-nav {
  list-style: none;
  padding: 0;
}

.sidebar .sidebar-nav li {
  padding: 10px 6px;
    cursor: pointer;
    color: #ccc;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 8px 15px;
    border-radius: 5px;
    transition: background-color 0.2s, color 0.2s;
}

.sidebar .sidebar-nav li svg {
  height: 26px;
  width: 26px;
}

.sidebar .sidebar-nav li:hover {
  background-color: #333;
  color: #f7c948;
}

.sidebar .sidebar-nav li.active {
  background-color: #f7c948;
  color: #000000;
  font-weight: 600;
}

.sidebar .toggle-button {
  display: none;
}

/* Responsive Sidebar */
@media (min-width: 768px) and (max-width: 1000px) {
  /* Adjust sidebar width */
  .sidebar {
    width: 70px;

  }

  .sidebar .sidebar-header img {
    display: none;

  }

  .sidebar:hover {
    width: 240px;
    position: fixed;
    z-index: 4000;
    transition: all ease-in-out .2s;
  }
  .sidebar:hover .sidebar-nav li span  {
    width: 240px;
    display: inline;
  }

  .sidebar:hover .sidebar-header img {
    display: flex;

  }

  .sidebar .sidebar-nav li span{
    display: none;
  }




  
}


@media (max-width: 768px) {
  .sidebar {
    transform: translateX(0); /* Always visible on larger screens */
      position: fixed;
      z-index: 4000;
      transform: translateX(-100%);
  }

  .toggle-button {
    display: block;
  }
}

/* Toggle Button for Mobile View */
.toggle-button {
  position: fixed;
  top: 0px;
  left: 20px;
  z-index: 901;
  background-color: #f7c948;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 2rem;
}

/* Content Shifting */
.content {
  transition: margin-left 0.3s ease;
}

.content.shifted {
  margin-left: 240px; /* Adjust based on sidebar width */
}

/* Overlay for Mobile */
/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  display: none;
}

.overlay.show {
  display: block;
} */
