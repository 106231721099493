.thank-you-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  /* background-color: #f7f9fc; */
  font-family: Arial, sans-serif;
  margin: 7px;
}

.thank-you-container {
  background: white;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  text-align: center;
}

.check-icon {
  font-size: 3rem;
  color: #28a745;
  margin-bottom: 1rem;
}

.thank-you-page h1 {
  color: #333;
  margin-bottom: 0.5rem;
}

.thank-you-page p {
  color: #666;
  margin-bottom: 1.5rem;
}

.details-section {
  text-align: left;
  margin-top: 1.5rem;
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.details {
  margin-top: 1rem;
}

.details div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.details div strong {
  color: #333;
}

.details div span {
  color: #555;
}

.thank-you-footer {
  margin-top: 2rem;
}

.back-home-button {
  display: inline-block;
  padding: 0.7rem 1.5rem;
  background: #1a103d;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s;
  margin: 7px;
}

.back-home-button:hover {
  background: #f7c948;
}
