.balance-overview {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .balance-overview h2 {
    margin-bottom: 20px;
  }
  
  .ag-theme-alpine {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .loading-overlay {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    color: #666;
  }


  .order-details{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000061;
    z-index: 10000;
  }
  