.update-mac-popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .update-mac-popup-content {
    background: #fff;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .update-mac-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .update-mac-popup-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .mac-address-display {
    margin-bottom: 20px;
  }
  
  .mac-address-display p {
    margin: 0;
    color: #555;
  }
  
  .mac-address-display strong {
    display: block;
    margin-top: 5px;
    font-size: 16px;
    color: #333;
  }
  
  .new-mac-input {
    margin-bottom: 20px;
  }
  
  .new-mac-input label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }
  
  .new-mac-input input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .popup-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .action-button {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
  }
  
  .action-button.update {
    background-color: #f7c948;
    color: #fff;
  }

  .action-button.update:hover {
    background-color: #f0b02f;
   
  }
  
  .action-button.cancel {
    background-color: #f5f5f5;
    color: #333;
  }

  .action-button.cancel:hover {
    background-color: #c2c2c2;
  }

  .checkbox-transf-container {
    margin: 15px 0;
    display: flex;
    align-items: center;
  }
  
  .custom-checkbox-transf-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    position: relative;
  }
  
  .custom-checkbox-transf {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .custom-checkbox-transf-mark {
    width: 18px;
    height: 18px;
    border: 2px solid #fbb034; /* Primary color */
    border-radius: 3px;
    background-color: #fff;
    margin-right: 10px;
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }
  
  .custom-checkbox-transf:checked + .custom-checkbox-transf-mark {
    background-color: #fbb034; /* Primary color */
    border-color: #fbb034;
  }
  
  .custom-checkbox-transf-mark:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  .custom-checkbox-transf:checked + .custom-checkbox-transf-mark:after {
    display: block;
  }
  
  