.forgot-password-container {
    display: flex;
        justify-content: center;
        align-items: center;
        /* height: 100vh; */
        background-color: #f1f1f1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
  }
  
  .forgot-password-box {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .forgot-password-box h2 {
    margin-bottom: 20px;
    font-size: 1.8rem;
    color: #1a103d;
    background-color: #f7c948;
    color: #333;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 10px 0 33px 0;
    margin: -2rem -2rem 1.5rem;
    width: 238px;
    height: 50px;
    align-content: center;
    box-shadow: 3px 2px 9px #80808078;
  }
  
  .forgot-password-box form {
    display: flex;
    flex-direction: column;
  }
  
  .forgot-password-box label {
    text-align: left;
    margin-bottom: 5px;
    color: #333;
  }
  
  .forgot-password-box input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #f7c948;
    border-radius: 5px;
    outline: none;
  }
  
  .forgot-password-box .reset-button {
    background-color: #f7c948;
    color: #1a103d;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .forgot-password-box .reset-button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
  }
  
  .forgot-password-box .reset-button:hover:not(:disabled) {
    background-color: #e6b93e;
  }
  
  .success-message {
    color: green;
    margin-bottom: 15px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 15px;
  }
  
  .login-links {
    margin-top: 15px;
  }
  
  .login-links .login-link {
    color: #1a103d;
    text-decoration: none;
  }
  
  .login-links .login-link:hover {
    text-decoration: underline;
  }
  