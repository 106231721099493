

  
  .terms-container {
    padding: 20px;
  }
  
  .terms-content {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .terms-container h1 {
    color: #1a103d;
    font-size: 2rem;
  }
  
  .terms-container h2 {
    color: #1a103d;
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .terms-container p, .terms-container ul {
    margin: 10px 0;
  }
  
  .terms-container ul {
    margin-left: 20px;
  }
  
  .terms-container .link {
    color: #007BFF;
    text-decoration: none;
  }
  
  .terms-container .link:hover {
    text-decoration: underline;
  }
  